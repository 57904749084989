@import '@angular/cdk/overlay-prebuilt.css';
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    .writing-mode-vertical {
        writing-mode: vertical-lr;
    }
}

@import "bootstrap-icons";

@font-face {
    font-family: "autumn";
    src: url("/assets/Autumn.otf");
}

@font-face {
    font-family: "aquire";
    src: url("/assets/Aquire.otf");
}

@font-face {
    font-family: "aquire-light";
    src: url("/assets/AquireLight.otf");
}

.font-aquire {
    font-family: "aquire";
}

.font-aquire-light {
    font-family: "aquire-light";
}

.btn {
    @apply px-3
    py-1
    rounded-sm
    text-white;
}

.btn-primary {
    @apply bg-primary-500
    hover:bg-primary-600
    active:bg-primary-400;
}

.btn-red {
    @apply bg-red-500
    hover:bg-red-600
    active:bg-red-400;
}

.btn-green {
    @apply bg-green-500
    hover:bg-green-600
    active:bg-green-400;
}

.btn-yellow {
    @apply bg-yellow-500
    hover:bg-yellow-600
    active:bg-yellow-400;
}

.btn-blue {
    @apply bg-blue-500
    hover:bg-blue-600
    active:bg-blue-400;
}

.btn-gray {
    @apply bg-gray-500
    hover:bg-gray-600
    active:bg-gray-400;
}

.tooltip[data-tooltip] {
    @apply underline
    cursor-help
    relative;

    &:hover:after,
    &:focus:after {
        content: attr(data-tooltip);
        @apply z-10
        absolute
        left-0
        top-6
        border
        w-96
        border-gray-400
        bg-gray-100
        rounded
        p-2;
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
select,
textarea {
    @apply rounded
    border
    border-gray-400
    focus:outline-1
    focus:outline-gray-500
    px-2
    py-1;

    &.ng-invalid.ng-dirty {
        @apply border-red-500
        focus:outline-red-500;
    }
}

.component-container {
    @apply border
    border-gray-300
    bg-white
    rounded
    relative
    mt-2
    shadow
    p-4;

    h1:first-of-type {
        @apply absolute
        bg-white
        px-1
        text-xs
        -top-2;
    }
}
